// All your details in this file
// Logo images
import logogradient from './assets/dnLogo.png';
import logo from './assets/logo2.svg';
// Profile Image
import profile from './assets/damien_profile.png';
// Tech stack images
import html from './assets/techstack/html.png';
import css from './assets/techstack/css.png';
import sass from './assets/techstack/sass.png';
import js from './assets/techstack/js.png';
import react from './assets/techstack/react.png';
import redux from './assets/techstack/redux.png';
import tailwind from './assets/techstack/tailwind.png';
import bootstrap from './assets/techstack/bootstrap.png';
import vscode from './assets/techstack/vscode.png';
import github from './assets/techstack/github.png';
import git from './assets/techstack/git.png';
import npm from './assets/techstack/npm.png';
import postman from './assets/techstack/postman.png';
import figma from './assets/techstack/figma.png';
// Project Images
import projectImage1 from './assets/projects/project1.jpg';
import projectImage2 from './assets/projects/project2.jpg';
import projectImage3 from './assets/projects/project3.jpg';
import projectImage4 from './assets/projects/project4.jpg';
import projectImage5 from './assets/projects/project5.jpg';
import projectImage6 from './assets/projects/project6.jpg';

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Personal Details here
export const personalDetails = {
  name: 'Damien Niyonshuti',
  tagline: 'Building technology for the future.',
  img: profile,
  about: `Seattle-based professional Software Engineer and Indie Hacker with over 3 years of experience working across diverse industries building enterprise software with major corporations, including four Fortune 500 companies and currently building a portfolio of small-bets SaaS startups. When I am not engineering, I like to lift weights and exploring nature.`,
};

// Social Media URLs here
export const socialMediaUrl = {
  linkdein: 'https://www.linkedin.com/in/damien-niyonshuti/',
  github: 'https://github.com/damienniyonshuti',
  twitter: 'https://twitter.com/DamienNiyonshu1',
  instagram: 'https://www.instagram.com/',
};

// Work Experience here
export const workDetails = [
  {
    Position: 'Founder',
    Company: `TEKx, LLC`,
    Location: 'Seattle, WA',
    Type: 'Part Time',
    Duration: 'Jan 2024 - Present',
  },
  {
    Position: 'Partner, Maxwell CTO',
    Company: `AskRadar.ai`,
    Location: 'Seattle, WA',
    Type: 'Part Time',
    Duration: 'Jan 2024 - Present',
  },
  {
    Position: 'Software Engineer II',
    Company: `Ford Motor Company`,
    Location: 'Seattle, WA',
    Type: 'Full Time',
    Duration: 'Jun 2023 - Present',
  },
  {
    Position: 'Software Engineer',
    Company: `Opendorse`,
    Location: 'Lincoln, NE',
    Type: 'Part Time',
    Duration: 'Sept 2022 - May 2023',
  },
  {
    Position: 'Capstone Product Lead',
    Company: `Olsson`,
    Location: 'Lincoln, NE',
    Type: 'Part Time',
    Duration: 'Aug 2022 - Apr 2023',
  },
  {
    Position: 'Software Engineer Intern',
    Company: `Nasdaq,Inc`,
    Location: 'Atlanta, GA',
    Type: 'Full Time',
    Duration: 'May 2022 - Aug 2022',
  },
  {
    Position: 'Software Engineer Intern',
    Company: `Kiewit Corporation`,
    Location: 'Lincoln, NE',
    Type: 'Part Time',
    Duration: 'Sept 2021 - May 2022',
  },
  {
    Position: 'Software Engineer Intern',
    Company: `United States Department of Agriculture (USDA))`,
    Location: 'Remote',
    Type: 'Part Time ',
    Duration: 'Jun 2021 - Aug 2021',
  },
  {
    Position: 'Software Engineering Experience',
    Company: `J.P. Morgan Chase & Co`,
    Location: 'Remote',
    Type: 'Full Time',
    Duration: 'Jun 2020 - Sept 2020',
  },
];

// Education Details here
export const eduDetails = [
  {
    Position: 'MS. Computer Science',
    Company: `Westcliff University`,
    Location: 'Irvine, California',
    Type: 'Full Time',
    Duration: 'Graduate',
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  react: react,
  redux: redux,
  sass: sass,
  tailwind: tailwind,
  bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
};

// Project Details here
export const projectDetails = [
  {
    title: 'AWS Messenger App',
    image: projectImage1,
    description: `A serverless application for triggering a multi-varied messaging service (Email or SMS).`,
    techstack: 'AWS, API Gateway, HTML/CSS, JavaScript',
    previewLink: '',
    githubLink: 'https://github.com/damienniyonshuti/AWS_MessengerAPP-',
  },
  {
    title: 'Learning Log',
    image: projectImage2,
    description: `A dynamic tool website for inputting and retrieving data synchronously per login user information.`,
    techstack: 'Python, Django, Heroku',
    previewLink: '',
    githubLink: 'https://github.com/damienniyonshuti/LearnigLog-App',
  },
  {
    title: 'Blender.Co',
    image: projectImage3,
    description: `A prototype for a Blockchain app built with a credit score rating algorithm for Ethereum crypto addresses.`,
    techstack: 'Web 3.0, HTML/CSS, JavaScript, Python, Algorithms',
    previewLink: 'https://devpost.com/software/blender-co',
    githubLink: 'https://github.com/ronaksingh22/blender.co',
  },
  {
    title: 'GiphyAPI-App',
    image: projectImage4,
    description: `An app that reads Giphy api endpoints and rendering them on the browser and allows the user to search their favorite Giphys.`,
    techstack: 'HTML/CSS, JavaScript',
    previewLink: '',
    githubLink: 'https://github.com/damienniyonshuti/GiphyAPI-app',
  },
  {
    title: 'GUI-FLox Automation ',
    image: projectImage5,
    description: `A program that creates a GUI which enables the user to input any metadata information and exports that into an excel sheet.`,
    techstack: 'Python, Django',
    previewLink: '',
    githubLink:
      'https://github.com/damienniyonshuti/GUI-FloX-metadata-Automation-Project',
  },
  {
    title: 'Demographic Data Analyzer',
    image: projectImage6,
    description: `Data processing and plotting program for >1000 population data in Jypter notebook.`,
    techstack: 'Python, HTML, CSS',
    previewLink: '',
    githubLink:
      'https://github.com/damienniyonshuti/demographic_data-_analyzer_Project',
  },
];

// Contact Details here
export const contactDetails = {
  email: 'dniyo.tech@gmail.com',
  phone: '+15317392941',
};
